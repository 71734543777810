import { useQuery } from '@tanstack/react-query';
import _, { get } from 'lodash';
import { SyncingWithScorabilityLogsRef } from '../../utils/refs';
import { getDocs, Timestamp } from 'firebase/firestore';

const fetchSendScoresToScorabilityErrors = async (slug) => {
  const collectionRef = SyncingWithScorabilityLogsRef();
  console.log('jere');
  // get all documents from collection in the last 24 hours
  const querySnapshot = await getDocs(collectionRef);

  let count = 0;
  // filter out documents that are older than 24 hours
  querySnapshot.docs.filter((doc) => {
    // createdOn is a firebase timestamp
    const createdOn = doc.data().createdOn;
    // convert firebase timestamp to js date
    const createdOnDate = createdOn.toDate();
    // get the difference in milliseconds
    const difference = Date.now() - createdOnDate;
    // convert milliseconds to hours
    const differenceInHours = difference / 1000 / 60 / 60;
    // if the difference in hours is less than 24, return the doc
    const isResolved = doc.data().resolved;

    if (!isResolved) {
      count++;
      return doc;
    }
  });

  return count;
};

export default function useSendScoresToScorabilityErrors() {
  return useQuery({
    queryKey: ['SendScoresToScorabilityErrors'],
    queryFn: async () => fetchSendScoresToScorabilityErrors(),
    onError: (err) => {
      console.error('error fetching syncing errors with Scorability: ', err);
    }
  });
}
